<template>
  <div class="v-text-field" style="margin-left:1px">
    <v-subheader
      light
      class="v-label v-label--active"
      :class="{'pl-9': icon}"
      style="margin-bottom: -30px;">
        {{ `${$t(label)}${(required) ? ' *' : ''}` }}
    </v-subheader>
      <!-- :thumb-label="(readonly && 'always') || true" -->
    <v-slider
      v-model="slidersValue"
      thumb-label="always"
      :thumb-size="30"
      :min="options.min"
      :max="options.max"
      :step="options.step"
      :color="color"
      :prepend-icon="icon"
      :track-color="options.trackColor || 'secondary'"
      :readonly="fieldReadonly"
      :rules="rules"
      class="mt-7"
      @mouseup="value = slidersValue"
    >
      <v-tooltip slot="append" bottom v-if="$t(description)">
        <template v-slot:activator="{ on }">
          <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
        </template>
        <span>{{ $t(description) }}</span>
      </v-tooltip>
    </v-slider>
  </div>
</template>
<script>
import baseTextInput from '@/mixins/baseTextInput';
import _ from '@/misc/lodash';

export default {
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.slider',
    },
    description: {
      type: String,
      default: 'fields.sliderTooltip',
    },
    initial: {
      type: Number,
      default: 0,
    },
    default: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    slidersValue: 0,
  }),
  computed: {
    sliderMaxValue() {
      return this.options.max;
    },
  },
  watch: {
    sliderMaxValue(value) {
      if (typeof this.default === 'string' && this.default === 'max') {
        this.slidersValue = value;
        this.value = this.slidersValue;
      }
    },
  },
  mounted() {
    let value = this.initial;
    if (!_.isEmpty(this.$attrs.value)) {
      value = this.$attrs.value;
    }
    if (!_.isEmpty(this.default)) {
      if (typeof this.default === 'string' && this.default === 'max') {
        value = this.options.max;
      } else if (typeof this.default === 'number') {
        value = this.default;
      } else if (typeof this.default === 'string') {
        value = parseInt(this.default, 10);
      }
      this.value = value;
    }
    this.slidersValue = value;
  },
};
</script>
